/**
 *
 * @class
 */
let AircraftAircraftType = (function () {

    /**
     *
     * @type {object}
     * @private
     */
    let _private = {};

    /**
     *
     * @type {jQuery}
     */
    _private.form = $();

    /**
     *
     */
    _private.instance = this;

    /**
     *
     * @param {*} form
     */
    this.init = form => {
        form          = $(form);
        _private.form = form;
        form.data('AircraftAircraftType', this);
        _private.instance.bindEvents();
    };

    /**
     *
     * @param {*} [form]
     */
    this.bindEvents = form => {
        if (_.isUndefined(form)) {
            form = _private.form;
        }
        form.find('select[name="aircraft_type[object_id]"]')
            .unbind('change.changeAircraftType').on('change.changeAircraftType', event => {
                event.preventDefault();
                _private.changeAircraftType($(event.target));
            }
        ).hiddenChosen({search_contains: true});
        _private.initSerialNumberSync();
    };

    /**
     *
     * @param {*} aircraftTypeSelect
     * @param {object} [aircraftType]
     */
    _private.changeAircraftType = (aircraftTypeSelect, aircraftType) => {
        if (!aircraftType) {
            _private.loadAircraftType(aircraftTypeSelect);
            return;
        }
        let form = aircraftTypeSelect.parents('form');
        form.find('input[name=max_gross_mass]').uVal(aircraftType.aircraft_type['max_gross_mass']);
        _private.updateEngineSerialNumberFields(aircraftType.aircraft_type);
        _private.instance.bindEvents();
        form.trigger('aircraftTypeChanged', [aircraftType]);
//
    };

    /**
     *
     * @param {*} aircraftTypeSelect
     */
    _private.loadAircraftType = aircraftTypeSelect => {
        let aircraftTypeId           = parseInt(aircraftTypeSelect.val());
        let data                     = aircraftTypeSelect.parents('form').serializeObject();
        data.aircraft_type.object_id = aircraftTypeId;
        $.ajax({
            url:         urlHelper.getSimpleUrl('aircraft', 'load-aircrafttype'),
            type:        'post',
            data:        data,
            dataType:    'json',
            ajaxElement: $('body'),
            success:     response => {
                if (_.isUndefined(response.data)) {
                    return;
                }
                _private.changeAircraftType(aircraftTypeSelect, response.data);
            }
        });
    };


    /**
     *
     * @param {object} aircraftType
     */
    _private.updateEngineSerialNumberFields = aircraftType => {
        let fieldSet = _private.form.find('div.engine-cycle-container');
        if ($(fieldSet).length === 0) {
            return;
        }
        $(fieldSet).find('input[name*=eng_sn]').each((i, input) => {
            let fieldSet = $(input).parent('div.formelement').parent('fieldset');
            new FormObserver(fieldSet.parents('form')).removeElement(input);
            fieldSet.remove();
        });
        let snCount = 0;
        if (aircraftType.engine_cycles_definition && aircraftType.engine_cycles_definition.length) {
            snCount = aircraftType.engine_cycles_definition.filter(f => f.name.indexOf('serialnumber') !== -1).length;
        }
        for (let i = 0; i < snCount; i++) {
            let engFieldSet = $('<fieldset/>', {'class': ''}).append(
                $('<div/>', {'class': 'formelement text medium-small'})
                    .append($('<label/>', {
                        'for': 'eng_sn-' + i, 'text': EfbHelper._('aircraft.eng_sn').replace('%s', (i + 1))
                    })).append($('<input/>', {
                        'id':                'eng_sn-' + i,
                        'name':              'eng_sn[' + i + ']',
                        'type':              'text',
                        'value':             i,
                        'validate-type':     'string',
                        'validate-lteq':     '255',
                        'validate-required': 1
                    })
                ));
            $(fieldSet).append(engFieldSet);
            new FormObserver(engFieldSet.parents('form')).addElement(engFieldSet);
            engFieldSet.find('input').keyup();
        }
    };

    /**
     *
     * @param {string} name
     * @param {number} i
     * @param {object} fieldObject
     * @param {*} element
     * @param {string} labelPrefix
     * @returns {*}
     */
    _private.getFieldElements = function (name, i, fieldObject, element, labelPrefix) {
        $.each(fieldObject,
            function (fieldName, fieldType) {
                let value     = '';
                let labelText = labelPrefix + fieldName.toUpperCase();
                if (_.isObject(fieldType)) {
                    if (!_.isUndefined(fieldType.label)) {
                        labelText = fieldType.label;
                    }
                }
                let inputType = 'text';
                if (fieldName === 'aircraft_model') {
                    return;
                }
                if (fieldName === 'serialnumber' || fieldName === 'op_hours') {
                    inputType = 'hidden';
                }
                let inputElement = $('<input/>', {
                        type:  inputType,
                        name:  name + '[' + i + '][' + fieldName + ']',
                        value: value,
                        id:    name + '-' + i + '-' + fieldName + '-' + i + '-' + fieldName + ''
                    }
                );
                if (inputType === 'hidden') {
                    element.append(inputElement);
                    return;
                }
                element.append(
                    $('<div/>', {'class': 'formelement text small'}).append(
                        $('<label/>', {
                            'for':  name + '-' + i + '-' + fieldName + '-' + i + '-' + fieldName,
                            'text': labelText
                        })
                    ).append(inputElement)
                );
                labelPrefix = '';
            }
        );
        return element;
    };

    _private.initSerialNumberSync = () => {
        initIsRequiredValidator(_private.form.find('input[name*=eng_sn]'));
        _private.form.find('input[name*=eng_sn]').unbind('keyup.syncSerialNumber')
                .on('keyup.syncSerialNumber', event => {
                    let inputEng = $(event.target);
                    let name     = inputEng.attr('name');
                    if (_.isUndefined(name)) {
                        return;
                    }
                    let match = name.match(/\d+/g);
                    if (_.isUndefined(match[0])) {
                        return;
                    }
                    let i = parseInt(match[0]);
                    _private.form.find('input[name*="[' + i + '][serialnumber]"]').val(inputEng.val());

                });
    };

});